<template>
    <div class="b2c-page-bg">
        <div class="b2c-pc-card-header">
            <a href="/open-credit-card-vpbank">
                <img src="../../../assets/images/prev-arrow.png" alt=""/>
            </a>
            <span>THỂ LỆ CHƯƠNG TRÌNH KHUYẾN MẠI</span>
        </div>
        <div class="b2c-pc-card-content">
            <div class="row justify-content-center">
                <div class="col-md-10 col-sm-12 p-0">
                    <img class="donate-money-image" src="../../../assets/images/Bannnerrrrrrrr.png" alt=""/>
                </div>
                <div class="col-md-10 col-sm-12 promotion-rule-container">
                    <div class="promotion-rule-title">
                        THỂ LỆ CHƯƠNG TRÌNH KHUYẾN MẠI
                    </div>
                    <hr class="promotion-rule-hr" />
                    <div class="promotion-rule-content">
                        <p class="font-weight-bold">
                            1. Tên chương trình khuyến mại: “MỞ THẺ VPBANK NGAY- RINH 200K VỀ TAY”
                        </p>
                        <p>
                            <span class="font-weight-bold">2. Địa bàn (phạm vi) khuyến mại:</span>
                            <span class="font-weight-normal">Toàn quốc.</span>
                        </p>
                        <p>
                            <span class="font-weight-bold">3. Hình thức khuyến mại:</span>
                            <span class="font-weight-normal">Tặng 200.000 đồng vào tài khoản điện thoại cho khách hàng khi Khách hàng thỏa mãn điều kiện của chương trình nêu tại mục 5.</span>
                        </p>
                        <p>
                            <span class="font-weight-bold">4. Thời gian khuyến mại:</span>
                            <span class="font-weight-normal">Từ 20/02/2023 – 30/06/2023 hoặc đến khi hết ngân sách chương trình tùy theo điều kiện nào đến trước.</span>
                        </p>
                        <p>
                            <span class="font-weight-bold">5. Khách hàng của chương trình khuyến mại (đối tượng được hưởng khuyến mại):</span>
                            <span class="font-weight-normal">Khách hàng mở mới thẻ tín dụng VPBank thành công qua website itel.vn hoặc app My iTel, kích hoạt thẻ thành công trong thời gian diễn ra chương trình.</span>
                        </p>
                        <p>
                            <span class="font-weight-bold">6. Tổng ngân sách dành cho chương trình:</span>
                            <span class="font-weight-normal">60.000.000 đồng (Bằng chữ: Sáu mươi triệu đồng). Lưu ý: Chương trình có thể kết thúc trước hạn khi hết ngân sách.</span>
                        </p>
                        <p>
                            <span class="font-weight-bold">7. Nội dung chi tiết của chương trình:</span>
                            <ul class="font-weight-normal ul-detail-content">
                                <li>
                                    - Tặng 200.000 đồngvào tài khoản điện thoại cho khách hàng mở mới thẻ tín dụng VPBank thành công qua website itel.vn hoặc app My iTel,  kích hoạt thẻ thành công trong thời gian diễn ra chương trình.
                                </li>
                                 <li>
                                    - Các bước tham gia chương trình:
                                </li>
                            </ul>
                        </p>
                        <p>
                            <span class="font-weight-bold">A. Các bước đăng ký mở thẻ :</span>
                            <ul class="font-weight-normal ul-detail-step">
                                <li>
                                    Bước 01: Đăng nhập trên app My iTel hoặc truy cập website itel.vn.
                                </li>
                                <li>
                                    Bước 02: Vào ITEL SHOP và chọn mục “Tài chính – Bảo hiểm”, chọn “Mở thẻ tín dụng VPBank”.
                                </li>
                                <li>
                                    Bước 03: Xem nội dung thông tin về chương trình.
                                </li>
                                <li>
                                    Bước 04: Thực hiện nhập thông tin theo hướng dẫn để hoàn tất thủ tục đăng ký mở thẻ.
                                </li>
                            </ul>
                        </p>
                        <p>
                            <span class="font-weight-bold">B. Các bước nhận thưởng: Để nhận  Ưu đãi 200.000 đồng, Bạn vui lòng chú ý và thực hiện theo hướng dẫn sau:</span>
                            <ul class="font-weight-normal ul-detail-step">
                                <li>
                                    Bước 01: Hoàn thành kích hoạt thẻ theo hướng dẫn từ VPBank.
                                </li>
                                <li>
                                    Bước 02: Sau 5-10 ngày, kiểm tra email được gửi từ VPBank với tiêu đề "Thư cảm ơn Khách hàng đã tham gia chương trình mở thẻ Tín dụng theo chương trình liên kết đối tác VPBank -iTel".
                                </li>
                                <li>
                                    Bước 03: Truy cập link trong email, nhập thông tin để nhận thưởng 200.000 đồng từ iTel.
                                </li>
                            </ul>
                        </p>
                        <p>
                            <span class="font-weight-bold">Lưu ý quan trọng: </span>
                            <span class="font-weight-normal">Số điện thoại đăng ký mở thẻ là số điện thoại nhận 200.000 đồng nạp vào tài khoản khi thỏa mãn điều kiện của chương trình và Họ tên khách hàng, số điện thoại, địa chỉ email do khách hàng đăng ký với iTel phải trùng khớp với thông tin đăng ký với ngân hàng VPBank.</span>
                        </p>
                        <p>
                            <span class="font-weight-normal">- Cách thức trao thưởng:</span>
                            <ul class="font-weight-normal ul-detail-step">
                                <li>
                                    Khách hàng nhận tiền ngay  vào tài khoản điện thoại của Khách hàng theo số điện thoại đã đăng ký nhận thưởng (sau tối đa 2 giờ).
                                </li>
                                <li>
                                    Trường hợp hệ thống lỗi không trả thưởng được cho khách hàng thì iTel sẽ đối soát và chi trả bổ sung cho khách hàng vào tài khoản điện thoại trong vòng 15 ngày làm việc.
                                </li>
                                <li>
                                    Tổng đài chăm sóc Khách hàng của Itel: 0877 087 087(miễn cước iTel).
                                </li>
                            </ul>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    
}
</script>
<style lang="scss" scoped>
.promotion-rule-hr{
    display: none;
}
.ul-detail-step{
    padding-inline-start: 15px;
    list-style: disc;
    li{
        padding-bottom: 4px;
    }
}
.ul-detail-content{
    padding-inline-start: 15px;
    li{
        padding-bottom: 4px;
    }
}
.donate-money-image{
    width: 100%;
    cursor: pointer;
}
.b2c-page-bg {
  background: rgba(242, 242, 242, 1);
}
.b2c-pc-card-header{
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding-top: 19px;
    padding-bottom: 19px;
    padding-left: 120px;

    display: flex;
    align-items: center;
    column-gap: 22px;
    img{
        width: 9px;
        cursor: pointer;
    }
    span{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 11px;
        color: #424242;
    }
}
.promotion-rule-container{
    margin-top: 20px;
    padding: 52px 122px;
    background: #FFFFFF;
    .promotion-rule-title{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 24px;
        line-height: 32px;
        color: #E9182C;
        text-align: center;
    }
    .promotion-rule-content{
        font-family: 'Roboto';
        font-style: normal;
        font-weight: 700;
        font-size: 12px;
        line-height: 22px;
        text-align: justify;
        color: #000000;
        margin-top: 32px;
        p{
            margin-bottom: 5px;
        }
    }
}
@media screen and (max-width: 768px){
    .promotion-rule-hr{
        display: block;
    }
    .b2c-pc-card-header{
        background: #FFFFFF;
        padding-left: 24px;
        span{
            font-size: 18px;
        }
    }
    .b2c-pc-card-content{
        padding: 0;
    }
    .promotion-rule-container{
        margin-top: 0px;
        padding: 20px 29px;
        .promotion-rule-title{
            text-align: left;
            font-size: 18px;
            line-height: 24px;
        }
        .promotion-rule-content{
            margin-top: 16px;
        }
    }
}
@media screen and (min-width: 577px){
  .b2c-pc-card-content{
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
</style>